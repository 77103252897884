<template>
  <div>
    <div class="row pl-3">
          <b-card class="col-md-12">
            <div class="row">
              <div class="col-sm-8 pt-1 text-center h3 title-neon80 letter-space-3">
                MEDICOS DEL DIA
              </div>
              <div class="col-sm-4 text-right">
              <button class="btn btn-skyblue">Gestionar</button>
              </div>
            </div>
          </b-card>
    </div>
    <div class="col-md-12 mt-1" style="overflow-y: scroll;max-height:400px">
      <div class="row mt-3 pl-3">
        <div v-for="(result, index) in result_items" :key="index" class="col-md-4 mb-3 col-sm-4">
          <b-card>
            <div class="row">
              <div class="col-md-12 text-center">
                <b-avatar
                  :src="url + result.logo_perfil" badge badge-left badge-top
                  badge-variant="success">
                </b-avatar>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 pt-3 text-center">{{ result.nombre }}</div>
              <div class="col-md-12 text-center">Cel: {{ result.telefono }}</div>
              <div class="col-md-12 px-0 text-center">
                <p v-if="result.primer_turno" class="m-0"><span class="text-success">P. Turno:</span> {{ result.primer_turno }}</p>
                <p v-if="result.segundo_turno" class="m-0"><span class="text-success">S. Turno:</span> {{ result.segundo_turno }}</p>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12 text-center">
                <!-- <a href="#" class="mr-3 text-danger">
                  <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" class="bi bi-share"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11
                    2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718
                    3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5
                    2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5
                    1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
                  </svg>
                </a> -->
                <a :href="wp + result.phone" class="mr-3 text-dark" target="_blank">
                  <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" class="bi bi-telephone"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605
                    2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569
                    17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678
                    0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745
                    0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678
                    0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29
                    2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457
                    2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306
                    1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846
                    1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0
                    1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                  </svg>
                </a>
                <!-- <a href="#" class="text-dark">
                  <svg width="1.4em" height="1.4em" viewBox="0 0 16 16" class="bi bi-envelope"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2
                    2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1
                    1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82
                    8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1
                    11.114l4.758-2.876L1 5.383v5.73z"/>
                  </svg>
                </a> -->
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <infinite-loading @infinite="getApiDashboard">
        <div slot="spinner" class="text-success">Cargando más médicos...</div>
        <div slot="no-more" class="text-danger">No hay más médicos para mostrar</div>
        <div slot="no-results" class="text-warning">No hay médicos...</div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'Medicos',
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      url: process.env.VUE_APP_BASE,
      wp: 'http://wa.me/',
      current_page: 1,
      result_items: [],
    };
  },
  methods: {
    getApiDashboard($state) {
      const params = { params: { current_page: this.current_page } };
      axios.get('/clinic/dashboard', params).then(({ data }) => {
        if (data.data.result_items.length) {
          this.current_page += 1;
          this.result_items.push(...data.data.result_items);
          $state.loaded();
        } else {
          $state.complete();
        }
      }).catch((error) => {
        util.showNotify(error.response.data.message, 'error');
      });
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'Neon 80s';
  src: url('~@/assets/fonts/Neon.ttf') format('truetype');
}
.title-neon80
{
  font-family:'Neon 80s';
}
.letter-space-3{
  letter-spacing: 3px;
}
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.btn-skyblue:hover
{
  box-shadow: 0px 5px 20px #61c6f2;
}
</style>
