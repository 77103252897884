<template>
  <b-card title="Ingreso por Medico" class="h-100">
    <div v-if="false" class="table-responsive height-custom">
      <b-table hover :items="items" :fields="fields">
        <template v-slot:cell(medico)="data">
          <b-avatar :size="24" class="mr-2"></b-avatar><br>
          <small class="fontsize-custom">{{data.item.medico}}</small>
        </template>
        <template v-slot:cell(efectivo)="data">
          <small class="fontsize-custom">{{data.item.efectivo}}</small>
        </template>
        <template v-slot:cell(banco)="data">
          <small class="fontsize-custom">{{data.item.banco}}</small>
        </template>
        <template v-slot:cell(tarjeta)="data">
          <small class="fontsize-custom">{{data.item.tarjeta}}</small>
        </template>
        <template v-slot:cell(total_ingreso)="data">
          <small class="fontsize-custom">{{data.item.total_ingreso}}</small>
        </template>
        <template v-slot:cell(accion)>
          <button class="btn btn-skyblue btn-sm px-2 pt-2 pb-1">Ver</button>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'CitasProgramadas',
  data() {
    return {
      fields: [
        {
          key: 'medico', label: 'Medico', class: 'text-center px-0', thStyle: { width: '20%' },
        },
        {
          key: 'efectivo', label: 'Efectivo', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'banco', label: 'Banco', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'tarjeta', label: 'Tarjeta', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'total_ingreso',
          label: 'Total Ingreso',
          class: 'text-center px-0',
          thStyle: { width: '15%' },
        },
        {
          key: 'accion', label: '', class: 'text-center px-1', thStyle: { width: '5%' },
        },
      ],
      items: [
        {
          medico: 'Emelie Rubio',
          efectivo: '1.000,00 Bs',
          banco: '2.500,00 Bs',
          tarjeta: '2.500,00 Bs',
          total_ingreso: '2.500,00 Bs',
        },
        {
          medico: 'Amayah Britton',
          efectivo: '2.000,00 Bs',
          banco: '2.500,00 Bs',
          tarjeta: '2.500,00 Bs',
          total_ingreso: '2.500,00 Bs',
        },
        {
          medico: 'Daniel Quintero',
          efectivo: '3.000,00 Bs',
          banco: '2.500,00 Bs',
          tarjeta: '2.500,00 Bs',
          total_ingreso: '2.500,00 Bs',
        },
        {
          medico: 'Emelie Rubio',
          efectivo: '4.000,00 Bs',
          banco: '2.500,00 Bs',
          tarjeta: '2.500,00 Bs',
          total_ingreso: '2.500,00 Bs',
        },
        {
          medico: 'Emelie Rubio',
          efectivo: '5.000,00 Bs',
          banco: '2.500,00 Bs',
          tarjeta: '2.500,00 Bs',
          total_ingreso: '2.500,00 Bs',
        },
        {
          medico: 'Emelie Rubio',
          efectivo: '6.000,00 Bs',
          banco: '2.500,00 Bs',
          tarjeta: '2.500,00 Bs',
          total_ingreso: '2.500,00 Bs',
        },
      ],
    };
  },
};
</script>

<style scoped>
.bg-graylight{
  background-color: #f2f2f2;
}
.round-item{
  border-radius: 15px 15px;
}
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.btn-skyblue:hover
{
  box-shadow: 0px 5px 20px #61c6f2;
}
.height-custom
{
  max-height: 300px;
}
.fontsize-custom
{
  font-size: 10px;
}
</style>
