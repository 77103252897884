<template>
  <div class="main-content">
    <div class="row" v-if="false">
      <!-- Perfil, Usuarios y Tarjeta de Credito -->
      <div class="col-md-6 col-sm-12 mb-2">
        <div class="row">
          <!-- Perfil y Tarjeta -->
          <div class="col-md-6 col-sm-6 mb-2">
            <div class="row h-100">
              <div class="col-md-12 mb-2">
                <PerfilCard />
              </div>
              <div class="col-md-12">
                <CreditCard />
              </div>
            </div>
          </div>
          <!-- Fin Perfil y Tarjeta -->
          <!-- Seccion Usuarios -->
          <UsuariosCard />
          <!-- Fin Seccion Usuarios -->
        </div>
      </div>
      <!-- Fin Seccion Perfil, Usuarios y Tarjeta de Credito -->
      <!-- Citas Programadas -->
      <div class="col-md-6 mb-2 py-0 pr-0">
        <CitasProgramadas />
      </div>
      <!-- Fin Seccion Citas Programadas -->
    </div>

    <div class="row">
      <!-- Calendario -->
      <div class="col-md-6 pr-0 mb-2">
        <CalendarioCard />
      </div>
      <!-- Fin Seccion Calendario-->
      <!-- Medicos del Dia -->
      <div class="col-md-6">
        <Medicos />
      </div>
      <!-- Fin Seccion Medicos -->
    </div>
  </div>
</template>
<script>
import CreditCard from '../components/BaseCreditCard.vue';
import PerfilCard from '../components/BasePerfilCard.vue';
import UsuariosCard from '../components/TheUsuariosCard.vue';
import CitasProgramadas from '../components/TheCitasProgramadasCard.vue';
import CalendarioCard from '../components/TheCalendarCard.vue';
import Medicos from '../components/TheMedicosSection.vue';

export default {
  components: {
    CreditCard, PerfilCard, UsuariosCard, CitasProgramadas, CalendarioCard, Medicos,
  },
  data() {
    return {

    };
  },
};
</script>
<style  scoped>
</style>
