<template>
  <b-card>
    <div class="row">
      <div class="col-md-12">
        <span><b>Perfil</b></span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 pt-3 px-2">
        <b-card no-body class="bg-dark text-white o-hidden">
          <img src="@/assets/images/photo-long-1.jpg"  height="145px" alt />
        </b-card>
      </div>
      <div class="col-md-6 pt-4">
        <h6 class="pt-1">Jorge Eguez</h6>
        <h6>CEO</h6>
        <h6 class="pt-2 text-dark">
          Banco:<br>
          <span>0,00</span>
        </h6>
        <h6 class="pt-2 text-dark">
          Caja:<br>
          <span>300,00</span>
        </h6>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'PerfilCard',
};
</script>
