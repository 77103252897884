<template>
  <b-card class="pb-5">
    <div class="row mb-3">
      <div class="col-md-8"></div>
      <div class="col-md-4 text-right">
        <button class="btn btn-skyblue">Gestiona</button>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <b-calendar block
         label-no-date-selected='Ninguna Fecha Seleccionada'>
        </b-calendar>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'CalendarioCard',
};
</script>

<style scoped>
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.btn-skyblue:hover
{
  box-shadow: 0px 5px 20px #61c6f2;
}
</style>
