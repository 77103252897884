<template functional>
  <div class="card credit-card py-2 px-3">
    <div class="row">
      <div class="col-md-6">
        <img src="@/assets/images/visa_title.svg" width="60px">
      </div>
      <div class="col-md-6 pt-2"><b>Cobros con Tarjeta</b></div>
    </div>
    <b-card-text class="mt-4 letter-space-3"><b>⋆⋆⋆⋆ ⋆⋆⋆⋆⋆ 4765</b></b-card-text>
    <b-card-text><b>Total: <span>2.899,00</span></b></b-card-text>
  </div>
</template>

<script>
export default {
  name: 'CreditCard',
};
</script>
<style scoped>
.credit-card
{
  background-image: url("~@/assets/images/credit-card.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.letter-space-3{
  letter-spacing: 3px;
}
</style>
