<template>
  <b-card class="col-md-6 col-sm-6 px-0">
    <div class="row">
      <div class="col-md-12">
        <b-avatar class="mr-2"></b-avatar>
        <span><b>Usuarios</b></span>
      </div>
    </div>
    <div v-if="false" class="col-md-12 mt-2" style="overflow-y: scroll;max-height:200px">
      <div class="row">
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="danger" size="52px">
          </b-avatar>
          </div>
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="danger" size="52px">
          </b-avatar>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="warning" size="52px">
          </b-avatar>
        </div>
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="warning" size="52px">
          </b-avatar>
        </div>
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
        <div class="col-4">
          <b-avatar
            src="/img/1.a1d0bb11.jpg" badge badge-left badge-top
            badge-variant="success" size="52px">
          </b-avatar>
        </div>
      </div>
    </div>
    <div v-if="false" class="row text-center">
      <div class="col-md-6 pb-2 pt-4">
        <div class="col-md-12 h1"><b>14</b></div>
        <div class="col-md-12"><b>Members</b></div>
      </div>
      <div class="d-flex align-items-end">
        <button class="col-md-12 btn btn-skyblue">Manage Team</button>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'UsuariosCard',
};
</script>

<style scoped>
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.btn-skyblue:hover
{
  box-shadow: 0px 5px 20px #61c6f2;
}
</style>
